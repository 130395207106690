@import url('https://fonts.googleapis.com/css?family=JetBrains+Mono:400,700&display=swap&subset=latin-ext');

// ========== Global ========== //
:root {
    --dfark: #1e1210;
    --dark: #071215;
    --bluewood: #2b3253;
    --goldenrod: #ffce6c;
    --oasis: #fff2d7;
    --goldenrod60: rgba(255, 206, 108, .6);
    --spring-wood: #fcfcf9;
    --spring-wood-2: #f7f3ed;
    --east-bay: #545c99;
    --floral-white: #f7f3ed;
    --mirage: #1d2135;
    --grey-pampas: #fafaf7;
    --persimon: #ff6f4d;
    --gray500: #868686;
    --orange: #ff9e4b;
    --gray400: #b4b4b4;
    --desert-storm: #ededea;
    --cararra: #f5f5f2;
    --white: white;
    --primary50: #fafafa;
    --greyblue100: #f7f7f8;
    --pacific: #0093a0;
    --star-dust: #949492;
    --light-green: rgba(114, 209, 141, .2);
    --gray200: #f5f6f6;
    --primary700: #858585;
    --gray900: #282828;
}

// Font and Line Height
$global-font-family: 'JetBrains Mono', 'Helvetica Neue', sans-serif;
$global-font-size: 1.5em;
$global-font-weight: 400;
$global-line-height: 1.5rem;

// Color of the background
$global-background-color: #FFFCF9;
$global-background-color-dark: #1a2238;

// Color of the text
$global-font-color: #161209;
$global-font-color-dark: #ffffff;

// Color of the secondary text
$global-font-secondary-color: #a9a9b3;
$global-font-secondary-color-dark: #9daaf2;

// Color of the link
$global-link-color: #161209;
$global-link-color-dark: #ff6a3d;

// Color of the hover link
$global-link-hover-color: #1f90ba;
$global-link-hover-color-dark: #f4db7d;

// Color of the border
$global-border-color: #e52828;
$global-border-color-dark: #f4db7d;
// ========== Global ========== //
// ========== Scrollbar ========== //
// Color of the scrollbar
$scrollbar-color: #ff6a3d;

// Color of the hover scrollbar
$scrollbar-hover-color: #ff5623;
// ========== Scrollbar ========== //

// ========== Selection ========== //
// Color of the selected text
$selection-color: rgba(53, 166, 247, 0.25);
$selection-color-dark: rgba(50, 112, 194, 0.4);
// ========== Selection ========== //

// ========== Header ========== //
// Height of the header
$header-height: 2rem;

// Font family and size of the header title
$header-title-font-family: $global-font-family ;
$header-title-font-size: 1rem;

// Color of the header background
$header-background-color: #f8f8f8;
$header-background-color-dark: #1a2238;

// Color of the hover header item
$header-hover-color: #161209;
$header-hover-color-dark: #fff;

// Color of the search background
$search-background-color: #e9e9e9;
$search-background-color-dark: #363636;
// ========== Header ========== //

// ========== Single Content ========== //
// Font size of the TOC
$toc-title-font-size: 1.2rem;
$toc-content-font-size: 1rem;

// Color of the single link
$single-link-color: #2d96bd;
$single-link-color-dark: #55bde2;

// Color of the hover single link
$single-link-hover-color: #ef3982;
$single-link-hover-color-dark: #bdebfc;

// Color of the table background
$table-background-color: #fff;
$table-background-color-dark: #272c34;

// Color of the table thead
$table-thead-color: #ededed;
$table-thead-color-dark: #20252b;

// Color of the blockquote
$blockquote-color: #6bd6fd;
$blockquote-color-dark: #59c5ec;
// ========== Single Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
$pagination-link-color: #2d96bd;
$pagination-link-color-dark: #a9a9b3;

// Color of the hover link in pagination
$pagination-link-hover-color: #000;
$pagination-link-hover-color-dark: #fff;
// ========== Pagination ========== //

// ========== Code ========== //
// Font family and size of the code
$code-font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$code-font-size: .875rem;

// Color of the code
$code-color: #E74C3C;
$code-color-dark: #E5BF78;

// Color of the code background
$code-background-color: #f5f5f5;
$code-background-color-dark: #272C34;

$code-info-color: #9c9c9c;
$code-info-color-dark: #b1b0b0;

// --dfark: #1e1210;
// --dark: #071215;
// --bluewood: #2b3253;
// --goldenrod: #ffce6c;
// --oasis: #fff2d7;
// --goldenrod60: rgba(255, 206, 108, .6);
// --spring-wood: #fcfcf9;
// --spring-wood-2: #f7f3ed;
// --east-bay: #545c99;
// --floral-white: #f7f3ed;
// --mirage: #1d2135;
// --grey-pampas: #fafaf7;
// --persimon: #ff6f4d;
// --gray500: #868686;
// --orange: #ff9e4b;
// --gray400: #b4b4b4;
// --desert-storm: #ededea;
// --cararra: #f5f5f2;
// --white: white;
// --primary50: #fafafa;
// --greyblue100: #f7f7f8;
// --pacific: #0093a0;
// --star-dust: #949492;
// --light-green: rgba(114, 209, 141, .2);
// --gray200: #f5f6f6;
// --primary700: #858585;
// --gray900: #282828;