.home .home-profile .links {
  padding: .5rem;
  font-size: 3rem;
}

header .logo {
  min-height: 1.2em;
  height: 1em;
  vertical-align: text-bottom;
}

.home .home-profile .home-title {
  font-size: unquote("clamp(1rem, -1rem + 8.333vw, 2rem)");
}